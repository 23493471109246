<template>
    <div>
        <a-card title="公司基本信息">
            <template #extra>
                <a-button>编辑</a-button>
            </template>
            <a-descriptions bordered :column="{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }">
                <a-descriptions-item label="公司名称">建供易营。共享仓超</a-descriptions-item>
                <a-descriptions-item label="公司地址">沃尔特商业城1区3栋7楼</a-descriptions-item>
                <a-descriptions-item label="公司logo"> </a-descriptions-item>
                <a-descriptions-item label="所属行业">移动互联网</a-descriptions-item>
                <a-descriptions-item label="是否上市">已上市</a-descriptions-item>
                <a-descriptions-item label="公司规模">1000人以上</a-descriptions-item>
                <a-descriptions-item label="单双休">双休</a-descriptions-item>
                <a-descriptions-item label="上班时间">偶尔加班</a-descriptions-item>
                <a-descriptions-item label="是否加班">偶尔加班</a-descriptions-item>
                <a-descriptions-item label="公司福利">五险一金，定期体检，加班补助</a-descriptions-item>
                <a-descriptions-item label="法定代表人" >张三</a-descriptions-item>
                <a-descriptions-item label="注册资本" >注册资本</a-descriptions-item>
                <a-descriptions-item label="成立时间" >成立时间</a-descriptions-item>
                <a-descriptions-item label="公司介绍" >云南建供易营科技有限公司成立于云南昆明</a-descriptions-item>
            </a-descriptions>
        </a-card>
    </div>
</template>

<script>
    export default {
        name: "commpany"
    }
</script>

<style scoped>

</style>